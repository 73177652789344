import React from "react"
import clsx from "clsx"
import ILL_WAVES from "../../images/waves_section2.svg"
import ILL_CONSTRUCTION from "../../images/illustrations/section2.svg"
import styles from "./Section2.module.css"

const Section2 = props => {
  return (
    <div className={styles.root}>
      <img alt="Colorful Waves" className={styles.waves} src={ILL_WAVES} />
      <div className={styles.content}>
        <img
          alt="A man building code"
          className={styles.mainImage}
          src={ILL_CONSTRUCTION}
        />
        <h2 className={styles.tagline}>
          <i>Setting up the dev environment.</i>
        </h2>
      </div>
    </div>
  )
}

export default Section2
