import React from "react"
import { Link } from "gatsby"
import styles from "./Footer.module.css"
import Full_Logo from "../../images/logo.svg"
import Routes from "../../constants/routes"
const Footer = props => {
  return (
    <>
      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div
            style={{
              paddingRight: "20%",
            }}
          >
            {Routes.map(elm => {
              return (
                <Link to={elm.path} key={elm.name} target={elm.target}>
                  <p className={styles.links}>{elm.name}</p>
                </Link>
              )
            })}
          </div>
          <div>
            <img
              alt="Funcs.io Logo"
              style={{
                width: "200px",
              }}
              src={Full_Logo}
            />
            <p>© 2020 Funcs.io</p>
          </div>
          <div></div>
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: `<script type="text/javascript" language="javascript">
          var aax_size='728x90';
          var aax_pubname = 'funcsio0f-21';
          var aax_src='302';
        </script>
        <script type="text/javascript" language="javascript" src="https://c.amazon-adsystem.com/aax2/assoc.js"></script>`,
        }}
      ></div>
    </>
  )
}

export default Footer
