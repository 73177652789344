import React from "react"
import { Link } from "gatsby"
import styles from "./Nav.module.css"
import Logo from "../../images/logo_round.svg"
import { NavRoutes } from "../../constants/routes"

const Nav = props => {
  return (
    <nav className={styles.nav}>
      <div>
        <img alt="Funcs.io Logo - Rounded" className={styles.logo} src={Logo} />
      </div>
      <div className={styles.navLinkCont}>
        {NavRoutes.map(elm => {
          return (
            <Link to={elm.path} key={elm.name} target={elm.target}>
              <span key={elm.name}>{elm.name}</span>
            </Link>
          )
        })}
      </div>
    </nav>
  )
}

export default Nav
