import React from "react"
import SVGLogo from "../images/logo.svg"
import SEO from "../components/seo"
import styles from "../styles/index.module.css"
import clsx from "clsx"

import Nav from "../components/Nav"
import Hero from "../components/Hero"
import Section2 from "../components/Section2"
import Footer from "../components/Footer"

const IndexPage = () => (
  <React.Fragment>
    <SEO title="Home" />
    <div className={styles.root}>
      <div className={clsx([styles.PageSection, styles.Section1])}>
        <Nav />
        <Hero />
      </div>
      <div className={styles.PageSection}>
        <Section2 />
      </div>
      <div className={styles.PageSection}>
        <Footer />
      </div>
    </div>
  </React.Fragment>
)

export default IndexPage
