import React from "react"
import clsx from "clsx"
import styles from "./Hero.module.css"
import ILL_Hero from "../../images/illustrations/hero.svg"

const Hero = props => {
  return (
    <div className={styles.heroCont}>
      <div className={styles.heroSection}>
        <h1 className={styles.heading}>
          <i>Funcs.io</i>
        </h1>
        <h2 className={styles.subHeading}>
          Building <span className={styles.highlight}>Func</span>tional Product
          <span className={styles.highlight}>s</span>.
        </h2>
      </div>
      <div className={styles.heroSection}>
        <img
          alt="Hero Image - Hand with polys"
          className={styles.heroImage}
          src={ILL_Hero}
        />
      </div>
    </div>
  )
}

export default Hero
