const Routes = [
  {
    name: "Home",
    path: "/",
    target: "_self",
  },
  {
    name: "Blog",
    path: "https://blog.funcs.io",
    target: "_blank",
  },
  {
    name: "Products",
    path: "/",
    target: "_self",
  },
  {
    name: "About Us",
    path: "/",
    target: "_self",
  },
]

const NavRoutes = [
  {
    name: "Products",
    path: "/",
    target: "_self",
  },
  {
    name: "Blog",
    path: "https://blog.funcs.io",
    target: "_blank",
  },

  {
    name: "Contact Us",
    path: "/",
    target: "_self",
  },
]

export { NavRoutes }

export default Routes
